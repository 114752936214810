import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React from "react";
import { BtnEnterSite, HeroBackground2, HeroForeground, HeroMidground, Logo } from "src/assets";
import LineSupportButton from "src/main/components/LineSupportButton";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Go989</VisuallyHidden>
      <img
        alt="logo"
        src={Logo}
      />

      <img
        alt="enter site"
        src={BtnEnterSite}
      />

      <Box sx={styles.images}>
        <img
          alt="hero background"
          src={HeroBackground2}
          style={{
            animation: "herobg ease-out 1.2s infinite",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: -10,
          }}
        />

        <img
          alt="hero midground"
          src={HeroMidground}
          style={{
            animation: "heromid ease-out 1.2s infinite",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 56,
          }}
        />

        <img
          alt="hero foreground"
          src={HeroForeground}
          style={{
            animation: "herofore ease-out 1.2s infinite",
            // left: 96,
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 122,
          }}
        />
      </Box>
      <LineSupportButton />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "10px",
    pb: "50px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    color: "white",
    cursor: "pointer",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "360px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;
